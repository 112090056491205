import {
  Box,
  BoxProps,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import { random, range } from "lodash";
import { memo } from "react";
import tinygradient from "tinygradient";
import { FallbackFullPage } from "~/components/Fallback/Fallback";

const darkGradient = "linear-gradient(180deg, #050514 0%, #1A1A31 100%)";

export function AuthPageContainer({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary fallback={FallbackFullPage}>
      <Flex w="100%" bg="white">
        <Flex grow={1} justify="center" h="100vh" p={6} w="50%">
          <Stack
            w="100%"
            direction={"column"}
            justify={"center"}
            align="center"
            spacing={6}
          >
            {children}
          </Stack>
        </Flex>
        <Flex hideBelow={"lg"} w={"50%"} h="100%" p={6}>
          <Flex
            w="100%"
            position="relative"
            borderRadius={"30px"}
            shadow={"lg"}
            overflow={"hidden"}
            transition={"all 1s ease"}
            background={darkGradient}
          >
            <IsoBlocks />
          </Flex>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
}

const gradient = tinygradient(["#b12a90", "#e16462", "#fca636"]);
const blocks = 48;

const IsoBlocks = memo(function IsoBlocks() {
  return (
    <Flex
      position="relative"
      justify={"center"}
      align={"center"}
      width={"100%"}
      height={"100%"}
    >
      <SimpleGrid
        p={10}
        columns={4}
        position={"absolute"}
        gap={2}
        top={0}
        left={0}
        right={0}
        bottom={0}
        margin="auto"
        alignContent={"center"}
        w={"100%"}
        maxW={"600px"}
        transform="rotateX(60deg) rotateZ(-30deg) scale(1)"
        style={{
          transformStyle: "preserve-3d",
        }}
      >
        {range(blocks).map((i) => (
          <IsoBlockText key={i} opacity={Math.floor(i / 4) / (blocks / 4)} />
        ))}
        <IsoBlockText>
          <Image src={"/enurgen-white.png"} height={4} maxW="fit-content" />
        </IsoBlockText>
        <IsoBlockText></IsoBlockText>
        <IsoBlockText></IsoBlockText>
        <IsoBlockText></IsoBlockText>
        <IsoBlock label={"D"} />
        <IsoBlock label={"U"} />
        <IsoBlock label={"E"} />
        <IsoBlock label={"T"} />
        <IsoBlockText>Design</IsoBlockText>
        <IsoBlockText>Model</IsoBlockText>
        <IsoBlockText>Compare</IsoBlockText>
        <IsoBlockText>Monitor</IsoBlockText>
        {range(blocks, 0).map((i) => (
          <IsoBlockText key={i} opacity={Math.floor(i / 4) / (blocks / 4)} />
        ))}
      </SimpleGrid>
    </Flex>
  );
});

function IsoBlockText({
  children,
  ...props
}: { children?: React.ReactNode } & BoxProps) {
  return (
    <Center borderWidth={"thin"} borderColor={"gray.600"} {...props}>
      <Text
        fontFamily={"mono"}
        color="whiteAlpha.900"
        m={6}
        fontSize={"lg"}
        textAlign={"center"}
      >
        {children}
      </Text>
    </Center>
  );
}

interface IsoBlockProps {
  label: string;
  transform?: string;
}
function IsoBlock({ label, transform }: IsoBlockProps) {
  return (
    <Box shadow={"lg"} transform={transform}>
      <SimpleGrid
        columns={3}
        gap={0.5}
        borderWidth={"thin"}
        borderColor={"gray.200"}
        p={2}
        position={"relative"}
      >
        <Center position={"absolute"} width={"100%"} height={"100%"}>
          <Text
            fontSize={"10em"}
            fontFamily={"mono"}
            fontWeight={700}
            color="blackAlpha.800"
            zIndex={2}
          >
            {label}
          </Text>
        </Center>

        {range(42).map((i) => {
          return (
            <Box
              key={i}
              className="gridItem"
              background={gradient.css("radial", "ellipse at bottom")}
              width="100%"
              height="5px"
              margin="3px auto"
              mixBlendMode="color-burn"
              opacity={random(0.4, 1)}
            />
          );
        })}
      </SimpleGrid>
    </Box>
  );
}
