import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@duet/ui/theme/theme";
import { Global, css } from "@emotion/react";
import { type ReactNode } from "react";
import { Provider } from "react-redux";
import { AuthProvider } from "./features/auth/AuthProvider";
import { store } from "./redux/store";
import { TrackingAuthUser } from "./tracking/TrackingAuthUser";

export function AppProviders({ children }: { children: ReactNode }) {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: "bottom-right",
          variant: "subtle",
        },
      }}
    >
      <Provider store={store}>
        <AuthProvider>
          <Global
            styles={css`
              .stats-gl {
                position: fixed !important;
                margin-top: 120px;
                left: 20px !important;
              }
            `}
          />
          <TrackingAuthUser />
          {children}
        </AuthProvider>
      </Provider>
    </ChakraProvider>
  );
}
