import {
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useAppUrls } from "~/appUrls";
import { useAuth } from "~/features/auth/AuthContext";
import { useActiveUser } from "~/features/user/hooks";
import { RouterLink } from "../Link";

export function AppBarUserAvatar() {
  const auth = useAuth();
  const { user } = useActiveUser();
  const fullName =
    user?.first_name ?? user?.last_name
      ? [user?.first_name, user?.last_name].join(" ")
      : user?.email ?? "";
  const url = useAppUrls();

  if (!auth.isLoggedIn) return null;
  return (
    <Menu>
      <MenuButton>
        <Avatar size="sm" name={fullName} />
      </MenuButton>
      <MenuList
        boxShadow={"lg"}
        color="black"
        fontFamily={"body"}
        textTransform={"none"}
      >
        <MenuGroup title={fullName}>
          <MenuItem as={RouterLink} to={url.settingsUserAccountEdit()}>
            Settings
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
