import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { FormField } from "~/components/FormField";
import { PasswordInput } from "~/components/FormField/PasswordInput";
import { RouterLink } from "~/components/Link";
import { useAuth } from "~/features/auth/AuthContext";
import {
  useFeatureFlags,
  useIsFeatureFlagsLoaded,
} from "~/features/featureFlag/hooks";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import { ampli } from "~/tracking/amplitude";
import { AuthError } from "../components/AuthError";
import { AuthFormContainer } from "../components/AuthFormContainer";
import { AuthPageContainer } from "../components/AuthPageContainer";

export function RegisterPage() {
  const urls = useAppUrls();

  const form = useForm({
    defaultValues: {
      username: "",
      password: "",
      confirm: "",
    },
  });
  const { errors, isDirty, isSubmitted } = form.formState;

  const auth = useAuth();

  const { isGaOpenRegistrationEnabled, isGaThirdPartyLoginEnabled } =
    useFeatureFlags(["ga-open-registration", "ga-third-party-login"]);

  const isFeatureFlagsLoaded = useIsFeatureFlagsLoaded();

  useDocumentTitle("Sign Up");

  if (
    auth.isLoggedIn ||
    (!isGaOpenRegistrationEnabled && isFeatureFlagsLoaded)
  ) {
    return <Navigate to="/" />;
  }

  return (
    <AuthPageContainer>
      <AuthFormContainer title="Sign Up">
        <Box w="100%" maxW={"480px"}>
          <form
            onSubmit={form.handleSubmit(({ username, password }) => {
              auth.firebaseCreateUserWithEmailAndPassword(username, password);
              ampli.signUp({
                email: username,
              });
            })}
          >
            <Stack direction="column" spacing={5}>
              <FormField label="Email">
                <Input
                  type="email"
                  placeholder="user@enurgen.com"
                  {...form.register("username")}
                />
              </FormField>

              <FormField label="Password">
                <PasswordInput
                  placeholder="Password"
                  {...form.register("password", { required: true })}
                />
              </FormField>
              <FormField
                label="Confirm Password"
                error={errors.confirm?.message}
                isInvalid={!!errors.confirm}
              >
                <PasswordInput
                  placeholder="Confirm Password"
                  {...form.register("confirm", {
                    required: true,
                    validate: async (confirm, { password }) => {
                      if (confirm !== password) {
                        return "Passwords do not match";
                      }
                    },
                  })}
                />
              </FormField>
              {isSubmitted && isDirty && !auth.isLoading && !!auth.err && (
                <AuthError err={auth.err} />
              )}
              <Button
                type="submit"
                variant={"primary"}
                isLoading={auth.isLoading}
              >
                Sign Up
              </Button>
              {isGaThirdPartyLoginEnabled && (
                <>
                  <Box position="relative" px={10} py={4}>
                    <Divider />
                    <AbsoluteCenter bg="white" px="4">
                      Sign Up With
                    </AbsoluteCenter>
                  </Box>
                  <Button variant="outline" rightIcon={<FaMicrosoft />}>
                    Microsoft
                  </Button>
                  <Button variant="outline" rightIcon={<FaGoogle />}>
                    Google
                  </Button>
                </>
              )}

              <HStack w="100%" justify="center">
                <Text textAlign={"center"}>Already have an account? </Text>
                <Link
                  as={RouterLink}
                  to={urls.loginRoute()}
                  fontWeight={"bold"}
                  textDecoration={"underline"}
                >
                  Log in
                </Link>
              </HStack>
            </Stack>
          </form>
        </Box>
      </AuthFormContainer>
    </AuthPageContainer>
  );
}
