import { Box, Button, Divider, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { useAuth } from "~/features/auth/AuthContext";
import {
  useFirebaseEmailVerificationSuccessMutation,
  useFirebaseResendEmailVerificationMutation,
} from "~/gql/generated";
import { AuthFormContainer } from "../components/AuthFormContainer";
import { AuthPageContainer } from "../components/AuthPageContainer";

export function EmailVerificationIntercept() {
  const urls = useAppUrls();
  const auth = useAuth();

  const [
    firebaseResendEmailVerification,
    firebaseResendEmailVerificationResult,
  ] = useFirebaseResendEmailVerificationMutation();

  const [
    firebaseEmailVerificationSuccess,
    // firebaseEmailVerificationSuccessResult,
  ] = useFirebaseEmailVerificationSuccessMutation();

  async function handleEmailVerificationSuccess() {
    if (!auth.firebaseAuth.currentUser) return;
    const token = await auth.firebaseAuth.currentUser.getIdToken();
    const isVerified = await firebaseEmailVerificationSuccess({
      token,
    });
    if (!isVerified) return;
    auth.refreshToken();
  }

  useEffect(() => {
    if (!auth.firebaseAuth.currentUser?.emailVerified) return;
    handleEmailVerificationSuccess();
  }, [auth.firebaseAuth.currentUser?.emailVerified]);

  if (!auth.authTokenParsed?.intercept) {
    return <Navigate to={urls.projectOpenRoute()} />;
  }

  if (!auth.isLoggedIn) {
    return <Navigate to={urls.loginRoute()} />;
  }

  return (
    <AuthPageContainer>
      <AuthFormContainer title="Verify Your Email">
        <Box w="100%">
          <Stack direction="column" spacing={5}>
            <Text>
              Complete your registration by verifying your email address. We
              sent you an email with a link to verify your email address.
            </Text>
            <Text>
              If you did not receive the email, please check your spam folder or{" "}
              <Button
                variant="link"
                isLoading={firebaseResendEmailVerificationResult.isLoading}
                onClick={() => firebaseResendEmailVerification()}
              >
                click here to resend
              </Button>
              .
            </Text>
            <Divider />
            <Button variant="link" onClick={auth.logout} textAlign="center">
              &larr; Back to Login
            </Button>
          </Stack>
        </Box>
      </AuthFormContainer>
    </AuthPageContainer>
  );
}
