import { isNil, sumBy } from "lodash";

export function multiplyIfDefined(
  multiplier: number | undefined | null,
  multiplicand: number | undefined | null
) {
  if (isNil(multiplier) || isNil(multiplicand)) {
    return undefined;
  }
  return multiplier * multiplicand;
}

export function divideIfDefined(
  dividend: number | undefined | null,
  divisor: number | undefined | null
) {
  if (isNil(dividend) || isNil(divisor)) {
    return undefined;
  }
  return dividend / divisor;
}

export function sumIfDefined(
  a: number | undefined | null,
  b: number | undefined | null
) {
  if (isNil(a) || isNil(b)) {
    return undefined;
  }
  return a + b;
}

export function subtractIfDefined(
  a: number | undefined | null,
  b: number | undefined | null
) {
  if (isNil(a) || isNil(b)) {
    return undefined;
  }
  return a - b;
}

export function findMedian(values: number[]) {
  if (values.length === 0) {
    return undefined;
  }
  const sortedValues = [...values].sort((a, b) => (a > b ? 1 : -1));
  if (sortedValues.length % 2 === 1) {
    return sortedValues[Math.floor(sortedValues.length / 2)];
  } else {
    const middleIndex = Math.floor(sortedValues.length / 2);
    return (sortedValues[middleIndex] + sortedValues[middleIndex - 1]) / 2;
  }
}

export function getWeightedAverage(
  inputs: Array<{ value: number; weight: number }>
) {
  if (inputs.length === 0) {
    return undefined;
  }

  const sumOfWeights = sumBy(inputs, "weight");
  return sumBy(inputs, ({ value, weight }) => (value * weight) / sumOfWeights);
}
