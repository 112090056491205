import { createSlice, isAnyOf, type PayloadAction } from "@reduxjs/toolkit";
import { api, type UserFragmentFragment } from "~/gql/generated";

interface UserState {
  user: UserFragmentFragment | null;
}

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserFragmentFragment>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(api.endpoints.UserMe.matchFulfilled),
      (state, action) => {
        state.user = action.payload.me;
      }
    );
  },
});

export const { setUser } = userSlice.actions;
