import { Icon } from "@chakra-ui/react";
import {
  MIN_num_rows,
  MIN_num_tables_per_row,
} from "@duet/shared/pvComponents/projectParameters";
import {
  PanelFormValue,
  TableFormValue,
} from "@duet/shared/pvComponents/types";
import {
  calculateMinTorqueTubeHeight,
  calculateModuleDimensionsFromPanelComponent,
} from "@duet/shared/pvComponents/utils";
import { Feature, Polygon } from "@turf/turf";
import {
  Dictionary,
  entries,
  find,
  intersection,
  isInteger,
  isNil,
  isNumber,
  keys,
} from "lodash";
import { TbCircleCheckFilled, TbCircleLetterI } from "react-icons/tb";
import { z } from "zod";
import { Project } from "~/features/projectsCommon/components/ProjectProvider";
import { BlockLayer, GetPanelIvPayload } from "~/gql/generated";
import { BlockModel, blockSchema } from "../blocks/blockModel";
import { calculateBlockArrayParams } from "../blocks/calculateBlockArrayParams";
import { ComponentType, componentTypeMeta } from "../pvComponents";
import { BlockSectionStats } from "../reducers/projectBlockSlice";
import {
  ComponentData,
  ComponentModel,
} from "../reducers/projectComponentSlice";

export function componentSectionIsComplete(
  component: ComponentModel,
  schema: z.SomeZodObject
) {
  return intersection(component.errorFields, keys(schema.shape)).length === 0;
}

export const statusIcons = {
  complete: <Icon as={TbCircleCheckFilled} color="green.400" />,
  inComplete: <Icon as={TbCircleLetterI} color="gray.400" />,
};

export function blockIsComplete(
  block: BlockModel,
  componentData: ComponentData,
  panelIvById: Dictionary<GetPanelIvPayload> | undefined,
  blockLayers: BlockLayer[],
  sectionStats: BlockSectionStats,
  frames: Array<Feature<Polygon>> | undefined
) {
  if (!blockSchema.safeParse(block).success) {
    return false;
  }

  for (const [componentType, { blockRelationFieldKey }] of entries(
    componentTypeMeta
  )) {
    if (!blockRelationFieldKey) {
      continue;
    }

    const component = find<ComponentModel>(
      componentData[componentType as ComponentType].items,
      (item) => item.id === block[blockRelationFieldKey]
    );
    if (!component || component.hasFieldErrors) {
      return false;
    }
  }

  const panel = componentData[ComponentType.Panel].items.find(
    (item) => item.id === block.pv_panel_id
  );
  const table = componentData[ComponentType.Table].items.find(
    (item) => item.id === block.pv_table_id
  );

  if (
    !panel ||
    !table ||
    panelIvById?.[panel.id]?.iv_params_invalid ||
    validateModuleDimensions(panel.parameters).isInvalid ||
    validateSufficientTorqueTubeHeight(panel.parameters, table.parameters)
      .isInsufficient
  ) {
    return false;
  }

  const { num_rows, num_table_per_row } = calculateBlockArrayParams(
    block,
    blockLayers,
    sectionStats,
    frames
  );

  return (
    validateNumRows(num_rows) && validateNumTablesPerRow(num_table_per_row)
  );
}

export function validateNumInverters(num_inverters: number): boolean {
  return isInteger(num_inverters) && num_inverters >= 1;
}

export function validateSoilingLoss(
  blockSimParams: Pick<
    BlockModel,
    "pv_soiling_algorithm" | "cleaning_interval_days" | "soiling_rate"
  >
) {
  if (blockSimParams.pv_soiling_algorithm !== "sawtooth") {
    return true;
  }
  const { soiling_rate, cleaning_interval_days } = blockSimParams;
  if (isNumber(soiling_rate) && isNumber(cleaning_interval_days)) {
    return (soiling_rate / 100) * cleaning_interval_days < 1;
  } else {
    return true;
  }
}

export function validateNumRows(num_rows: number): boolean {
  return num_rows >= MIN_num_rows;
}

export function validateNumTablesPerRow(num_table_per_row: number): boolean {
  return num_table_per_row >= MIN_num_tables_per_row;
}

export function validateSufficientTorqueTubeHeight(
  pv_panel: PanelFormValue,
  pv_table: TableFormValue
): { isInsufficient: boolean; minValue?: number } {
  const { torque_tube_height, tracking_type } = pv_table;

  if (tracking_type !== 1) {
    // for fixed-tilt it just needs to be >=0 (already validated by zod schema)
    return { isInsufficient: false };
  }

  if (!isNumber(torque_tube_height)) {
    return { isInsufficient: false };
  }

  const minTorqueTubeHeight = calculateMinTorqueTubeHeight(pv_panel, pv_table);

  if (!isNumber(minTorqueTubeHeight)) {
    return { isInsufficient: false };
  }
  return {
    isInsufficient: torque_tube_height < minTorqueTubeHeight,
    minValue: minTorqueTubeHeight,
  };
}

export function validateModuleDimensions(pv_panel: PanelFormValue) {
  const { height, width } =
    calculateModuleDimensionsFromPanelComponent(pv_panel);

  return {
    isInvalid: isNumber(height) && isNumber(width) && height < width,
    height,
    width,
  };
}

export function validateGridInjectionLimit(
  system_parameters: Project["system_parameters"] | undefined
) {
  if (!system_parameters?.grid_injection_limit_enabled) {
    return { isInvalid: false };
  }
  if (isNil(system_parameters.grid_injection_limit)) {
    return { isInvalid: false };
  }
  return {
    isInvalid: system_parameters.grid_injection_limit <= 0,
  };
}
