import { Box, Center, Divider, Heading, Image, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface AuthFormContainerProps {
  title: string;
  children: ReactNode;
}

export function AuthFormContainer({ title, children }: AuthFormContainerProps) {
  return (
    <Stack w="100%" maxW={"480px"} align="center" p={8} spacing={4}>
      <Center w="75px" py={2} borderRadius={"2xl"} justifySelf={"start"}>
        <Image src="/duet-nue-black.png" />
      </Center>

      <Box position="relative" px={10} py={0} w={"100%"}>
        <Divider />
      </Box>
      <Heading as="h2" size="md">
        {title}
      </Heading>
      {children}
    </Stack>
  );
}
