import { Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { useAuth } from "./AuthContext";

export function AuthRoute({ component }: { component: React.ReactNode }) {
  const { isLoggedIn, authTokenParsed } = useAuth();
  const urls = useAppUrls();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={urls.loginRoute()} />;
  }

  if (isLoggedIn && authTokenParsed?.intercept) {
    if (
      authTokenParsed?.intercept === "email_verification" &&
      location.pathname !== urls.emailVerification()
    ) {
      return <Navigate to={urls.emailVerification()} />;
    }
  }

  return <Fragment>{component}</Fragment>;
}
