import { Button, Link } from "@chakra-ui/react";
import { TbMail } from "react-icons/tb";

export function AppBarMailQuestion() {
  return (
    <Button
      as={Link}
      href="https://docs.google.com/forms/d/e/1FAIpQLScB44YHfOKVT5XXoVtkS8tlejaUTylI4Ct1iHPHS6rIIjhpRw/viewform?pli=1"
      target="_blank"
      rightIcon={<TbMail />}
      size="sm"
      variant="link"
      color="whiteAlpha.900"
      _hover={{}}
      borderColor={"whiteAlpha.900"}
      fontWeight={"normal"}
    >
      Contact
    </Button>
  );
}
