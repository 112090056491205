import { Center, Flex, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { startCase } from "lodash";
import { Fragment, ReactNode } from "react";
import { TbCloudExclamation } from "react-icons/tb";
import { Link as RouterLink } from "react-router-dom";
import { urls } from "~/appUrls";
import { AppBar } from "../AppBar";

interface NotFoundProps {
  resource: "project" | "simulation" | "page" | "workbook";
  link?: {
    to: string;
    label: ReactNode;
  };
}

export function NotFound({ resource = "page", link }: NotFoundProps) {
  return (
    <Fragment>
      <Flex direction="column" w="100vw" h="100vh" position={"relative"}>
        <AppBar />
        <Center w="100%" h="100%">
          <Stack>
            <Icon as={TbCloudExclamation} boxSize={10} />
            <Text
              as="h2"
              fontSize={"4xl"}
              fontFamily={"mono"}
              fontWeight={"black"}
            >
              {startCase(resource)} Not Found
            </Text>
            <Text>
              {`The ${resource} you are looking for does not exist, or you don't have access to the ${resource}.`}
            </Text>
            <Link
              as={RouterLink}
              to={link?.to ?? urls.indexRoute()}
              textDecoration={"underline"}
            >
              {link?.to ? link.label : <>Go to Projects &rarr;</>}
            </Link>
          </Stack>
        </Center>
      </Flex>
    </Fragment>
  );
}
