import { useMemo } from "react";
import { useLocation } from "react-router-dom";

interface RouteWithQueryParamsOptions {
  searchParams: URLSearchParams;
  ignoreExistingParams?: boolean;
}
// this is made and is more of a hack
//  ideally we fix up the URL class to return some kind of Route Builder class
// that we can use
//  ex:

// urls.projectRoute(foo).query({bar: baz}).toString()
// urls.projectRoute(foo).stripQuery().query({bar: baz}).toString()
// urls.projectRoute(foo).query({bar: baz}).toLocation()
export const routeWithQueryParams = (
  url: string,
  { searchParams, ignoreExistingParams }: RouteWithQueryParamsOptions
) => {
  const [route, qs] = url.split("?");
  const existingParams = new URLSearchParams(
    ignoreExistingParams ? "" : qs ?? ""
  );

  searchParams.forEach((value, key) => existingParams.set(key, value));

  return `${route}?${existingParams.toString()}`;
};

// TODO: searchParams shouldn't always be retained across routes
export class Urls {
  searchParams: URLSearchParams;
  constructor(searchParams: URLSearchParams = new URLSearchParams()) {
    this.searchParams = searchParams;
  }

  // TODO: this should return a RouteBuilder class see comment above routeWithQueryParams
  private readonly route = (route: string) => {
    const qs = this.searchParams.toString();
    if (qs.length > 0) {
      return `${route}?${qs}`;
    }

    return route;
  };

  adminFlags = () => {
    return this.route("/admin/flags");
  };

  adminInviteUser = () => {
    return this.route("/admin/invite");
  };

  adminImpersonateUser = () => {
    return this.route("/admin/impersonate");
  };

  adminInverterDataset = () => {
    return this.route("/admin/inverter");
  };

  indexRoute = () => {
    return this.route("/");
  };

  loginRoute = () => {
    return this.route("/auth/login");
  };

  registerRoute = () => {
    return this.route("/auth/register");
  };

  passwordReset = () => {
    return this.route("/auth/password-reset");
  };

  invitationRegister = (token: string) => {
    return this.route(`/auth/invitation/${token}`);
  };

  emailVerification = () => {
    return this.route("/auth/email-verification");
  };

  projectCreateRoute = () => {
    return this.route("/designer/project/create");
  };

  projectOpenRoute = () => {
    return this.route("/designer/project/open");
  };

  projectRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}`);
  };

  projectDesignRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/design`);
  };

  projectDesignBlockRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/design/blocks`);
  };

  projectDesignComponentRoute = (
    projectId: string,
    componentType?: string,
    componentId?: string
  ) => {
    return this.route(
      `/designer/project/${projectId}/design/components` +
        (componentType ? `/${componentType}` : "") +
        (componentId ? `/${componentId}` : "")
    );
  };

  projectSimRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/compute`);
  };

  projectSimBlocksRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/compute/blocks`);
  };

  projectSimMeteoRoute = (projectId: string, enviroDatasetId?: string) => {
    return this.route(
      `/designer/project/${projectId}/compute/meteo` +
        (enviroDatasetId ? `/${enviroDatasetId}` : "")
    );
  };

  projectSimulationsIndexRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/simulations`);
  };

  projectSimulationRoute(projectId: string, simulationId: string) {
    return this.route(
      `/designer/project/${projectId}/simulations/${simulationId}`
    );
  }

  projectSimulationsDetailRoute(
    projectId: string,
    simulationId: string,
    stage?: string
  ) {
    return this.route(
      `/designer/project/${projectId}/simulations/${simulationId}` +
        (stage ? `/${stage}` : "/energy-yields")
    );
  }

  projectCompareIndexRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/compare`);
  };

  projectCompareWorkbookRoute = (projectId: string, workbookId: string) => {
    return this.route(`/designer/project/${projectId}/compare/${workbookId}`);
  };

  projectMonitorIndexRoute = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/monitor`);
  };

  projectMonitorPlantExplorer = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/plant-explorer`);
  };

  projectMonitorPerformance = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/performance`);
  };

  projectMonitorModelVsActual = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/model-vs-actual`);
  };

  projectMonitorIrradiance = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/irradiance`);
  };

  projectMonitorAlerts = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/alerts`);
  };

  projectMonitorIssues = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/issues`);
  };

  projectMonitorOverview = (projectId: string) => {
    return this.route(`/designer/project/${projectId}/overview`);
  };

  projectMonitorTimeSeriesAnalysis = (projectId: string) => {
    return this.route(
      `/designer/project/${projectId}/monitor/time-series-analysis`
    );
  };

  projectMonitorStubPage = (projectId: string, title: string) => {
    return this.route(`/designer/project/${projectId}/monitor/${title}`);
  };

  projectMonitorTableIrradianceProfile = (projectId: string) => {
    return this.route(
      `/designer/project/${projectId}/monitor/table-irradiance`
    );
  };

  projectMonitorPlantPlayback = (projectId: string, date: string) => {
    return this.route(
      `/designer/project/${projectId}/monitor/plant-playback/${date}`
    );
  };

  projectPlantPlayback = (projectId: string, id?: string) => {
    return this.route(
      `/designer/project/${projectId}/plant-playback${id ? `/${id}` : ""}`
    );
  };

  settingsIndexPage = () => {
    return this.route("/settings");
  };

  settingsUserApiTokens = () => {
    return this.route("/settings/user/api-tokens");
  };

  settingsUserAccountEdit = () => {
    return this.route("/settings/user/account/edit");
  };

  pv3Playground = (scene?: string) => {
    return this.route(`/pv3${scene ? `/${scene}` : ""}`);
  };

  static withQuery = routeWithQueryParams;
}

export const url = new Urls();
export const urls = url;

export function useAppUrls() {
  const { search } = useLocation();
  return useMemo(() => new Urls(new URLSearchParams(search)), [search]);
}

export function stripQuerystring(url: string) {
  return url.replace(/(\?.*)/g, "");
}

export function isPathnameActive(
  aUrl: string,
  bUrl: string = "",
  { startsWith } = { startsWith: false }
) {
  if (startsWith) {
    return stripQuerystring(aUrl).startsWith(stripQuerystring(bUrl));
  }
  return stripQuerystring(aUrl) === stripQuerystring(bUrl);
}
